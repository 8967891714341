/* Header Section*/
.intro {
  display: table;
  width: 100%;
  padding: 0;
  background: url(../public/img/intro-bg2.jpg) center center no-repeat;  background-color: #e5e5e5;
  background-size: cover;
}

/* Desktop-specific styles */
@media (min-width: 992px) {
  .intro h1 {
    font-size: 68px;
    margin-top: -100px;
    margin-bottom: 10px;
  }

  .intro p {
    font-size: 22px;
    line-height: 30px;
  }

  header .intro-text {
    padding-top: 350px;
    padding-bottom: 200px;
  }
}

/* Mobile adjustments (now properly contained) */
@media (max-width: 991px) {
  .intro {
    background-position: 65% center;
  }

  .intro h1 {
    font-size: 32px;
    margin-top: -50px !important;
    line-height: 1.2;
  }

  .intro p {
    font-size: 16px !important;
    line-height: 1.4;
    margin-bottom: 30px !important;
  }

  header .intro-text {
    padding-top: 150px !important;
    padding-bottom: 100px !important;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* Universal header styles */
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}

.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}

.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}

.intro p {
  color: #fff;
  margin: 0 auto;
}



/* Gallery Styles */
.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.category-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
}

.category-item:hover {
  transform: translateY(-5px);
}

.category-title {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-size: 2.5rem;
  text-shadow: 0 2px 4px rgba(0,0,0,0.3);
  z-index: 2;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  padding: 2rem 0;
}

.image-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.image-container:hover {
  transform: scale(1.03);
}

.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container:hover .hover-overlay {
  opacity: 1;
}

.hover-content {
  text-align: center;
  color: white;
}

.view-icon {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 0.5rem;
}

.back-button {
  background: #5ca9fb;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.4rem;
  transition: background 0.3s ease;
  margin-bottom: 2rem;
}

.back-button:hover {
  background: #4a8dc7;
}

.selected-category-title {
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
}

.gallery-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  display: block;
}

/* Category-specific sizing */
.category .gallery-image {
  height: 400px;
}

@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: 1fr;
  }
  
  .gallery-image {
    height: 250px;
  }
  
  .category .gallery-image {
    height: 300px;
  }
  
  .category-title {
    font-size: 1.2rem;
  }
}